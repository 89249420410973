import React from 'react'
import css from './Footer.module.css'
import Logo from '../../assets/logo.png'
import {
    InboxIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon
} from '@heroicons/react/outline'

const Footer = () => {
  return (
    <div className={css.cFooterWrapper}>
        <hr />
        <div className={css.cFooter}>
            <div className={css.logo}>
                <img src={Logo} alt='' />
                <span>amazon</span>
               
            </div>


            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact Us
                    </span>
                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon} />
                        <span>111 North Ave. Orlando, FL 32801</span>
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>
                    <span className={css.pngLine}>
                        <UsersIcon className={css.icon} />
                        <a href='/about'>About us</a>
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resources</span>
                    <span className={css.pngLine}>
                        <LinkIcon className={css.icon} />
                        <p>Safety and privacy terms</p>
                    </span>
                </div>
            </div>

           


        </div>
        <div className={css.copyRight}>
                <span>Copyright 2022 by Amazon Inc</span>
                <span>All Rights Reserved</span>
            </div>
    </div>
  )
}

export default Footer